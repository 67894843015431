export default {
  title: {
    en: 'Find your<br/>scent profile',
  },
  subtitle: {
    en: 'Tell us about your fragrance preferences to<br/>find your scent profile.'
  },
  takeQuiz: {
    en: 'Take our quiz'
  },
  retakeQuiz: {
    en: 'Retake our quiz'
  },
  scentType: {
    en: 'Scent type'
  },
  slides: [
    {
      en: 'Addictive, sensual & creamy'
    },
    {
      en: 'Sweet, creamy & comfortable'
    },
    {
      en: 'Fresh, lush & easy-going'
    },
    {
      en: 'Fresh, bold & sophisticated'
    },
    {
      en: 'And 9 more<br/>scent profiles!'
    },
  ]
}
